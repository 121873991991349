@charset "utf-8";
#AggregatesContainer{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    background-color: #F7F8FA;
}
#AggregatesContainer #AggregatesList{
    text-align: center;
}
#AggregatesContainer #AggregatesList .AggregatesListItem:hover .AggregatesImage,
#AggregatesContainer #AggregatesList .AggregatesListItem:focus .AggregatesImage{
    transform: scale(1.3);
}
#AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesImage{
    transition: .3s;

}
#AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesImage,
#AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}
#AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer{
    text-align: left;
    color: #153768;
}
#AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer .AggregatesTitle,
#AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer .AgggregatParagraph{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
#AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer .AggregatesTitle{
    font-weight: bold;
}
@media screen and (min-width: 701px) {
    #AggregatesContainer{
        padding: 3vh 0;
    }
    /*#AggregatesContainer #AggregatesList{
        text-align: center;
    }*/
    #AggregatesContainer #AggregatesList .AggregatesListItem{
        padding: 3vh 1%;
        margin: 0% 1%;
        width: 20%;
        display: inline-block;
        vertical-align: middle;
    }
    #AggregatesContainer #AggregatesList .AggregatesListItemBorder{
        border-right: 1px solid #153768;
    }
    /*#AggregatesContainer #AggregatesList .AggregatesListItemBorder{}*/
    #AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesImage{
        height: 50px;
        width: auto !important;
        margin-right: 10px;
    }
    #AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer{
        width: 60%;
    }
    #AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer .AggregatesTitle{
        font-size: .9em;
        margin-bottom: 10px;
    }
    #AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer .AgggregatParagraph{
        font-size: .8em;
    }
}
@media screen and (max-width: 700px) {
	#AggregatesContainer{
        padding: 3vh 0;
    }
    /*#AggregatesContainer #AggregatesList{
        text-align: center;
    }*/
    #AggregatesContainer #AggregatesList .AggregatesListItem{
        margin: auto;
        padding: 3vh 0;
        display: block;
        width: 95%;
    }
    /*#AggregatesContainer #AggregatesList .AggregatesListItemBorder{}*/
    #AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesImage{
        height: 50px;
        width: auto !important;
        margin-right: 10px;
    }
    #AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer{
        width: 60%;
    }
    #AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer .AggregatesTitle{
        font-size: .9em;
        margin-bottom: 10px;
    }
    #AggregatesContainer #AggregatesList .AggregatesListItem .AggregatesTextContainer .AgggregatParagraph{
        font-size: .8em;
    }
}
